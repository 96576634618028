@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "SFPro";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("/public/fonts/SF-Pro-Text-Thin.otf") format("truetype");
}

@font-face {
  font-family: "SFPro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/public/fonts/SF-Pro-Text-Light.otf") format("truetype");
} */

@font-face {
  font-family: "SFPro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/public/fonts/SF-Pro-Text-Regular.otf") format("truetype");
}

@font-face {
  font-family: "SFPro";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/public/fonts/SF-Pro-Text-Medium.otf") format("truetype");
}

@font-face {
  font-family: "SFPro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/public/fonts/SF-Pro-Text-Bold.otf") format("truetype");
}

/* @font-face {
  font-family: "SFPro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/public/fonts/SF-Pro-Text-Heavy.otf") format("truetype");
} */

@font-face {
  font-family: "SFPro";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("/public/fonts/SF-Pro-Text-Black.otf") format("truetype");
}

* {
  font-family: "SFPro", sans-serif;
}
html {
  background: #000;
  color: white;
  line-height: 1.5;
  tab-size: 4;
}
body {
  font-family: inherit;
}
html,
body,
#__next {
  min-height: 100%;
  margin: 0px;
  background-repeat: no-repeat;
}
#root {
  overflow: hidden;
}
:root {
  --app-height: 100%;
}
.checkout-page {
  height: 100vh;
}
.checkout, .pioneer {
  height: 100vh;
  height: var(--app-height);
}
.pioneer::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
@media only screen and (min-width: 993px) {
  body,
  html {
    max-width: 100%;
  }
}
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  margin-block-start: 1em;
  -webkit-margin-after: 1em;
  margin-block-end: 1em;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  -webkit-padding-start: 40px;
  padding-inline-start: 40px;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button,
select {
  text-transform: none;
}
button {
  overflow: visible;
}
button:disabled {
  /* opacity: 0.5; */
  cursor: not-allowed !important;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
img {
  border: 0;
}
img,
video {
  max-width: 100%;
  height: auto;
}
input {
  line-height: normal;
  background: transparent;
  border: none;
  outline: none;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #333 inset !important;
    -webkit-text-fill-color: #FFF !important;
    caret-color: white;
}
a {
  outline: none;
}
input::placeholder {
  color: #888888;
}
*,
::before,
::after {
  box-sizing: border-box;
}
h1,
h2 {
  line-height: 110%;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

@keyframes mouse-scroll {
  0% {
    transform: translate(50%, 0px);
  }
  50% {
    transform: translate(50%, -10px);
  }
  100% {
    transform: translate(50%, 0px);
  }
}

article {
  font-weight: 700;
  font-size: 2.813em;
  line-height: 55px;
  letter-spacing: -0.05em;
}

@media (max-width: 768px) {
  article {
    font-size: 2.625em;
    line-height: 51px;
  }
}

@media (max-width: 360px) {
  article {
    font-size: 2.25em;
    line-height: 45px;
  }
}

@media (max-width: 320px) {
  article {
    font-size: 1.375em;
    line-height: 31px;
  }
}

::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #B374FC;
  border-radius: 5px;
}

.gradient-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.imgradient-text {
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background-clip: unset;
  text-fill-color: unset;
}

.plan-slider .carousel-initialized {
  overflow: unset !important;
}

.plan-slider .carousel-prev {
  transform: translateX(-20px);
}

.plan-slider .carousel-next {
  transform: translateX(20px);
}

.prive-carousel-item {
  display: flex !important;
}
.cart:hover > img:last-of-type {
  opacity: 1 !important;
}

@keyframes leftone {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes rightone {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes flag1 {
  0% { transform: translate(0px, 0px) }
  20% { transform: translate(3px, 2px) }
  40% { transform: translate(6px, 5px) }
  60% { transform: translate(7px, 8px) }
  80% { transform: translate(2px, 4px) }
  100% { transform: translate(0px, 0px) }
}

@keyframes flag2 {
  0% { transform: translate(0px, 0px) }
  20% { transform: translate(-4px, 3px) }
  40% { transform: translate(-6px, 5px) }
  60% { transform: translate(5px, 4px) }
  80% { transform: translate(3px, -2px) }
  100% { transform: translate(0px, 0px) }
}

@keyframes flag3 {
  0% { transform: translate(0px, 0px) }
  20% { transform: translate(-1px, 8px) }
  40% { transform: translate(-3px, -2px) }
  60% { transform: translate(5px, 6px) }
  80% { transform: translate(7px, -4px) }
  100% { transform: translate(0px, 0px) }
}

@keyframes flag4 {
  0% { transform: translate(0px, 0px) }
  20% { transform: translate(-3px, 2px) }
  40% { transform: translate(-5px, -3px) }
  60% { transform: translate(1px, 3px) }
  80% { transform: translate(5px, 1px) }
  100% { transform: translate(0px, 0px) }
}

@keyframes flag5 {
  0% { transform: translate(0px, 0px) }
  20% { transform: translate(5px, -3px) }
  40% { transform: translate(2px, -3px) }
  60% { transform: translate(-1px, 3px) }
  80% { transform: translate(-4px, -2px) }
  100% { transform: translate(0px, 0px) }
}

@keyframes flag6 {
  0% { transform: translate(0px, 0px) }
  20% { transform: translate(5px, -1px) }
  40% { transform: translate(-2px, -3px) }
  60% { transform: translate(-1px, -5px) }
  80% { transform: translate(4px, -2px) }
  100% { transform: translate(0px, 0px) }
}

@keyframes flag7 {
  0% { transform: translate(0px, 0px) }
  20% { transform: translate(1px, -1px) }
  40% { transform: translate(5px, -3px) }
  60% { transform: translate(5px, -5px) }
  80% { transform: translate(-4px, 2px) }
  100% { transform: translate(0px, 0px) }
}

@keyframes flag8 {
  0% { transform: translate(0px, 0px) }
  20% { transform: translate(3px, -1px) }
  40% { transform: translate(1px, -5px) }
  60% { transform: translate(1px, 5px) }
  80% { transform: translate(4px, 2px) }
  100% { transform: translate(0px, 0px) }
}

@keyframes flag9 {
  0% { transform: translate(0px, 0px) }
  20% { transform: translate(2px, -1px) }
  40% { transform: translate(-3px, -7px) }
  60% { transform: translate(5px, 7px) }
  80% { transform: translate(-4px, 2px) }
  100% { transform: translate(0px, 0px) }
}

@keyframes flag10 {
  0% { transform: translate(0px, 0px) }
  20% { transform: translate(8px, -1px) }
  40% { transform: translate(6px, -5x) }
  60% { transform: translate(-7px, 7px) }
  80% { transform: translate(-4px, 2px) }
  100% { transform: translate(0px, 0px) }
}

.animated-arrow1 {
  animation-name: leftone;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.animated-arrow2 {
  animation-name: rightone;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.prive-loading1 {
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.checkout-container {
  height: calc(100vh - 86px);
  height: calc(var(--app-height) - 86px);
}
.checkout-container::-webkit-scrollbar, .checkout-carousel-item::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.checkout-carousel-item {
  min-width: -webkit-fill-available;
}
@media (min-width: 1024px) {
  .plan-slider .carousel-initialized {
    pointer-events: none;
  }
}
@media (max-width: 1024px) {
  .plan-slider .carousel-item {
    transition: 0.3s opacity;
    opacity: 0.35;
  }

  .plan-slider .carousel-item > .carousel-row {
    transition: 0.3s transform;
    transform: scale(0.9) translateY(7.66px);
    transform-origin: top;
  }

  .plan-slider .carousel-item.active {
    opacity: 1;
  }

  .plan-slider .carousel-item.active > .carousel-row {
    transform: scale(1) translateY(0px);
  }
}
.dark:hover > div > span {
  background-image: linear-gradient(90deg, #2590ff 0%, #b373fb 99.99%);
}
.fire-button:disabled >div:first-of-type {
  background: #282828;
}
.fire-button:disabled >div:nth-of-type(2) {
  display: none;
}
.want-button >div{
  background: rgba(255, 255, 255, 0.25);
  color: white;
}
.want-button >div >svg{
  fill: white;
}
.checkout-container::-webkit-scrollbar {
  width: 0px;
}
.back-button:hover >div:last-of-type, .message-button:hover >div:last-of-type {
  opacity: 0.5;
  filter: blur(7.5px);
}
.plan-button >div:last-of-type {
  opacity: 1;
  filter: blur(7.5px);
}
.title {
  font-size: 40px;
  line-height: 47.73px;
  font-weight: 600;
}
.button-text {
  font-size: 14px;
  line-height: 17px;
}
.subtitle {
  font-size: 14px;
  line-height: 20px;
}
.subtitle > span {
  font-size: 16px;
}
.subtitle1 {
  font-size: 16px;
  line-height: 19.09px;
}
.threetext {
  font-size: 36px;
  line-height: 42.96px;
}
button:not(.agree-button) {
  height: 42px;
  border-radius: 22.5px;
}
button .arrow-right-hover {
  display: none;
}
button:hover .arrow-right-hover {
  display: block;
}
button:hover .arrow-right {
  display: none;
}
button:not(.agree-button) > div {
  border-radius: 22.5px;
}
.footergrid {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.flags >div {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.flags >div:first-of-type {
  animation-duration: 5s;
  animation-name: flag1;
}
.flags >div:nth-of-type(2) {
  animation-duration: 4s;
  animation-name: flag2;
}
.flags >div:nth-of-type(3) {
  animation-duration: 5s;
  animation-name: flag3;
}
.flags >div:nth-of-type(4) {
  animation-duration: 4s;
  animation-name: flag4;
}
.flags >div:nth-of-type(5) {
  animation-duration: 5s;
  animation-name: flag5;
}
.flags >div:nth-of-type(6) {
  animation-duration: 6s;
  animation-name: flag6;
}
.flags >div:nth-of-type(7) {
  animation-duration: 7s;
  animation-name: flag7;
}
.flags >div:nth-of-type(8) {
  animation-duration: 9s;
  animation-name: flag8;
}
.flags >div:nth-of-type(9) {
  animation-duration: 8s;
  animation-name: flag9;
}
.flags >div:nth-of-type(10) {
  animation-duration: 5s;
  animation-name: flag10;
}
@media (min-width: 500px) {
  .footergrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  .mobileLogo {
    width: 15% !important;
  }
  .payment::-webkit-scrollbar {
    width: 5px !important;
    height: 0px !important;
  }
}
@media (min-width: 1024px) {
  .footergrid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .title {
    font-size: 64px;
    line-height: 76.38px;
  }
  .button-text {
    font-size: 18px;
    line-height: 21px;
  }
  .subtitle {
    font-size: 18px;
    line-height: 28px;
  }
  .subtitle > span {
    font-size: 20px;
  }
  .subtitle1 {
    font-size: 20px;
    line-height: 23.87px;
  }
  .threetext {
    font-size: 60px;
    line-height: 71.6px;
  }
  button:not(.agree-button) {
    height: 43.74px;
  }
}
@media (min-width: 1340px) {
  .smartphone-desktop {
    right: -130px;
  }
}
@media (min-width: 1440px) {
  .footergrid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media (min-width: 1536px) {
  .earn-text {
    font-size: 4.5138vw !important;
  }
  .get-text {
    max-width: 40.2777vw !important;
    font-size: 1.6666vw !important;
  }
  .venmo-text {
    font-size: 1.25vw !important;
  }
  .twitter-follow {
    width: 13.2847vw !important;
    height: 4.1666vw !important;
    margin-top: 3.2638vw !important;
  }
  .twitter-follow >img{
    width: 3.0555vw;
    height: 2.3611vw;
  }
  .twitter-follow >div>span:first-of-type{
    font-size: 1.0069vw !important;
  }
  .twitter-follow >div>span:nth-of-type(2){
    font-size: 1.6180vw !important;
  }
  .waitlist {
    margin-top: 12.6388vw !important;
  }
  .title {
    font-size: 4.4444vw;
    line-height: 5.30416vw;
  }
  .button-text {
    font-size: 1.25vw;
    line-height: 1.1805vw;
  }
  .subtitle {
    font-size: 1.25vw;
    line-height: 1.9444vw;
  }
  .subtitle > span {
    font-size: 1.388vw;
  }
  .subtitle1 {
    font-size: 1.3888vw;
    line-height: 1.6576vw;
  }
  .threetext {
    font-size: 4.1666vw;
    line-height: 4.9722vw;
  }
  button:not(.agree-button) {
    border-radius: 1.5625vw;
    height: 3.0375vw;
  }
  button:not(.agree-button) > div {
    border-radius: 1.5625vw;
  }
  header > div {
    padding-top: 3.8194vw !important;
    padding-bottom: 2.4305vw !important;
  }
  .logo > img {
    width: 1.875vw;
    height: 2.2222vw;
  }
  .logo > span {
    margin-left: 0.625vw;
    font-size: 1.9444vw;
    line-height: 2.3201vw;
  }
  .trytext {
    font-size: 3.125vw !important;
    line-height: 3.7291vw !important;
  }
  .introduce {
    margin-top: 15.4861vw !important;
  }
  .anonymous {
    margin-top: 25.7638vw !important;
  }
  .coverage {
    margin-top: 29.0972vw !important;
  }
  .advanced-vpn {
    margin-top: 34.375vw !important;
  }
  .threeplans {
    margin-top: 23.8194vw !important;
  }
  .policy {
    margin-top: 13.4069vw !important;
  }
  .familyplans {
    margin-top: 12.5vw !important;
  }
  .tryitfree {
    margin-top: 15.3472vw !important;
  }
  .phone {
    width: 12.2222vw !important;
  }
  .phone-left {
    right: 9.7222vw !important;
  }
  .phone-right {
    left: 9.7222vw !important;
  }
  .phone-back {
    min-width: 62.5vw !important;
  }
}

.matrix-mobile {
  top: 60%;
  transform: translate(50%, -50%) rotate(10deg);
}